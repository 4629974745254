import type { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { NotFound } from '@/layouts';
import { Meta } from '@/layouts/Meta';
import { Main } from '@/templates/Main';

export const getStaticProps: GetStaticProps = async (context) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale || 'en', ['common'])),
    },
    revalidate: 10,
  };
};

export default function Page404() {
  return (
    <Main
      meta={
        <Meta
          title="Compare Best Deposit Accounts | PickTheBank"
          shouldBeIndexed={false}
          description="Find and compare the best deposit accounts in the EU. Discover high interest deposit accounts, secure online deposit accounts, and learn about the Deposit Guarantee Scheme."
          keywords="Best Deposit Accounts, High Interest Deposit Accounts, Compare Deposit Accounts, EU Deposit Accounts, Online Deposit Accounts, Secure Deposit Accounts, Deposit Guarantee Scheme"
        />
      }
    >
      <NotFound />
    </Main>
  );
}
